.font-size--large {
    font-size: 5rem;
}
.font-size-small {
    font-size: 1.3rem;
}
.fo
.font-size-medium {
    font-size: 1.9rem;
}
.font-family-base {
    font-family: $font-family-base;
}
.font-color-red {
    color: $red;
}
