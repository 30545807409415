//Comments section in words inside pages
.comments {
    &__container {
        &:not(:last-child)::after {
            content: '';
            width: 100%;
            height: 1px;
            background: rgba(0, 0, 0, .1);
            margin: 4rem 0;
            display: inline-block;
        }
    }

    &__form {
        .form-control {
            height: 25rem;
            font-weight: 300;
        }
    }
}
