.section {
    padding: 8rem 0;
    position: relative;
    @include media-breakpoint-down(md) {
        padding: 5rem 0;
    }

    .container {
        animation: fade-in 1s ease-in-out;
    }
}

.section-identifier {
    position: absolute;
    top: -5rem;
}

// Sections with background
    .section-background-1 {
        background: rgba($primary, .3) url(../img/random-words-bg.jpg) center center;
        background-size: cover;
    }
    .section-background-2 {
        background: url(../img/partners-bg.jpg) center center no-repeat;
        background-size: cover;
    }
