// Sidebar right - Words List
.sidebar {
    ul li {
        .list-item-title {
            text-transform: uppercase;
            color: $primary;
            line-height: 3.5rem;
            font-weight: 400;
        }
    }

    @include media-breakpoint-down(md) {
        align-items: flex-start;
        text-align: left;
        margin-top: 5rem;
    }
}

.grecaptcha-badge {
    top: 50%;
    z-index: 5;
}
