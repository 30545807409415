.react-autosuggest__container {
    position: absolute;
    right: 0;
    top: -3px;
    width: 100%;
    @include media-breakpoint-down(lg) {
        top: initial;
        bottom: 0;
    }
}

.flag-search--open {
    .react-autosuggest__input {
        opacity: 1;
        width: 95%;
        z-index: 9;
        font-size: $font-size-base;
        font-family: $font-family-secondary;
        border: 1px solid rgba($base, 0.3);
        @include media-breakpoint-down(lg) {
            position: absolute;
            width: 100%;
            left: -1px;
            bottom: 0;
        }
    }
}

.react-autosuggest__input {
    @extend .form-control;
    @extend .react-autosuggest__container;
    width: 0;
    opacity: 0;
    border: 1px solid rgba($base, 0.6);
    transition: all .3s;
    color: $base;
    height: auto;
    padding: 1.6rem;
    &:focus {
       box-shadow: none; 
       border: 1px solid rgba($base, 0.8);
    }
    &::placeholder {
        color: rgba($base, 0.4);
    }
}

.react-autosuggest__container--open {
    .search-expanded {
        border-radius: 0.5rem 0.5rem 0 0;
    }
}


.react-autosuggest__suggestions-container {
    width: 94.9%;
    top: 5.5rem;
    right: 0;
    flex: 1;
    position: absolute;
    max-height: 320px;
    overflow-y: auto;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    z-index: 10;
    @include media-breakpoint-down(lg) {
        width: 100%;
        top: 0.5rem;
    }

    @extend .card;

    ul {
        list-style: none;
        padding:0;
        margin:0;
    }
}

.react-autosuggest__suggestion {
    margin-bottom: 0;
    @extend .btn, .nav-link-btn;

}

.react-autosuggest__suggestion--highlighted {
    background: $primary;
    color: $white;
}
