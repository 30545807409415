html {
    // This defines what 1rem is
    font-size: 62.5%;
    font-weight: 300;
    height: 100%;
}

body {
    position: relative;
    margin: 0;
    min-height: 100%;
    color: $base;
}

h1, h2, h3, h4, h5 {
    font-family: $font-family-base;
}

a {
    transition: all .2s;

    &:hover,
    &:focus,
    &:active {
        color: $secondary;
        text-decoration: none;
    }
}

p {
    font-size: $font-size-base;
    color: $base;
    text-decoration: none;
    font-weight: 300;
    line-height: 2.5rem;
    font-family: $font-family-secondary;
}

ul {
    margin-bottom: 0;
}

li {
    font-size: $font-size-base;
    color: $base;
    font-weight: 300;
    line-height: 2.5rem;
    font-family: $font-family-secondary;
    margin-bottom: 1rem;
}

time {
    font-size: 1.3rem;
}
