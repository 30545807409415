.card {
    border: none;

    .card-body {
        border-radius: $card-border-radius;
        border: $card-border-width solid $card-border-color;
        .card-descr {
            height: 50px;
            overflow: hidden;
            font-family: $font-family-secondary;
        }
        p:last-child {
            margin-bottom: 0;
        }
        &:hover {
            .card-title {
                color: $red;
            }
        }
    }

    .card-title {
        text-transform: uppercase;
        font-size: 2rem;
        color: $primary;
        font-weight: 400;
        margin-bottom: 1rem;
        transition: all .5s;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &--small {
            font-size: 1.5rem;
        }
        &--secondary {
            font-size: 3rem;
        }
    }
    @include media-breakpoint-down(md) {
        text-align: left !important;
    }
}

// Card columns
.cards-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    transition: all .5s;
        .card-word {
            margin: 10px;
            width: 31%;
            height: 130px;
                @include media-breakpoint-down(md) {
                    width: 47%;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
        }
        .card-body {
            transition: 0.3s all;
            &:hover {
                box-shadow: 0 1rem 3rem rgba(51,51,51,.1);
                border: 1px solid #fff;
            }
        }
}
