.jumbotron {
    margin-bottom: 0;
    border-radius: 0;
    position: relative;
    background: url(../img/header-bg.jpg) center center no-repeat;
    width: 100%;
    background-size: cover;
    @include media-breakpoint-down(md) {
        &.overlay:before {
            position: absolute;
            content:" ";
            top:0;
            left:0;
            width:100%;
            height:100%;
            display: block;
            z-index:0;
            background-color: rgba(255,255,255,0.8);
        }
     }

    &--inside {
        padding: 2rem;
        min-height: 90px;
        .react-autosuggest__input,
        .flag-search--open .react-autosuggest__input {
            @include media-breakpoint-down(lg) {
               top: -50px;
            }
        }
        .react-autosuggest__suggestions-container {
            top: 5.5rem;
            @include media-breakpoint-down(lg) {
                top: 1rem;
             }
        }
    }
}
