.navbar {
    h1 {
        font-size: 4rem;
        font-weight: 500;
        @include media-breakpoint-down(lg) {
            font-size: 3rem;
        }
    }
    > .container {
        justify-content: start;
    }
    //Adding background on scroll
    &.sticky,
    &.open {
        transition: all .4s ease;
        z-index: 999;
        background: $white;
        box-shadow: $box-shadow-1;
        width: 100%;
    }
 

    ul {
        position: relative;
        .nav-item {
            margin: 0 1.5rem;
            @include media-breakpoint-down(lg) {
                width: 100%;
                margin: 1.2rem 1rem; 
            }

            .nav-link {
                text-decoration: none;
                text-transform: uppercase;
                color: $base;
                transition: all .3s;
                font-weight: 400;
                font-family: $font-family-secondary;

                &:active,
                &:focus,
                &:hover,
                .active {
                    color: $secondary;
                }
            }
        }
    }

    // Navbar Logo
    .navbar-brand {
        color: $base;
        font-weight: 500;
        z-index: 999;
    }
    .navbar-toggler {
        outline: none;

        &-icon {
            background: url(../img/menu.svg) no-repeat;
            width: 4rem;
            height: 3rem;
            background-size: 90%;
        }
    }
}
