// Sticky footer
.footer {
    background-color: $white;
    color: $base;
    padding: 2rem 0;
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    border-top: 1px solid rgba($base, 0.1);
    @include media-breakpoint-down(sm) {
        bottom: -100px;
    }
    p {
        font-size: 1rem;
        text-transform: uppercase;
        margin: 0;
        margin-top: .5rem;
        letter-spacing: .5px;
        font-family: $font-family-secondary;
        font-weight: 500;
    }
}
