// Forms
.form {
  label {
      color: $base;
      font-size: 2.4rem;
      font-family: $font-family-base;
      text-transform: uppercase;
  }

  .required-sign {
    color: $secondary;
    font-size: 3rem;
  }

  textarea {
      min-height: 15rem;
      resize: none;
  }

  .form-control {
    font-weight: 300;
    font-size: 1.5rem;
    font-family: $font-family-secondary;
    height: auto;
    padding: 1.6rem;
    &::placeholder {
      opacity: 0.5;
    }
  }
}
