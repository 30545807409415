.blockquote {
    &--style {
        font-size: 2rem;
        color: $base;
        line-height: 2.8rem;
        font-weight: 300;
        letter-spacing: .4px;
        font-family: $font-family-base;
    }
}

.blockquote--secondary {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid $base;
    font-style: italic;
    font-weight: 400;
}

