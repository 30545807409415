.c-warning {
  background: $red;
  z-index: 9999;
  position: fixed;
  bottom: 70px;
  width: 100%;
  padding: 2rem;
  left: 0;
  right: 0;
  p {
    color: #fff;
    text-align: center;
    margin: 0 auto;
  }
}
