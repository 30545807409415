//Buttons
.btn {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-family: $font-family-secondary;
    font-weight: 400;
    &:focus {
        box-shadow: none;
    }
}
.btn-simple {
    color: $primary;
    &:hover,
    &:focus,
    &:active,
    &.active{
        background-position: 100%;
        color: $secondary;
        box-shadow: none;
        :not(svg) {
            fill: $secondary;
        }
    }
}
.btn-outline-primary {
    @include button-outline-variant($primary);

    background-image: linear-gradient(115deg, transparent 0%, transparent 50%, $primary 50%);
    background-size: 230%;

    &:hover,
    &:focus,
    &:active,
    &.active{
        background-position: 100%;
        color: $white;
        box-shadow: none;

        :not(svg) {
            fill: $white;
        }
    }
}

.btn-outline-secondary {
    @include button-outline-variant($secondary);

    background-image: linear-gradient(115deg, transparent 0%, transparent 50%, $secondary 50%);
    background-size: 230%;


    &:hover,
    &:focus,
    &:active,
    &--active {
        background-position: 100%;
        color: $white;
    }
}

.btn-outline-danger {
    @include button-outline-variant($red);

    background-image: linear-gradient(115deg, transparent 0%, transparent 50%, $red 50%);
    background-size: 230%;

    &:hover,
    &:focus,
    &:active {
        background-position: 100%;
        color: $white;
    }
}

.btn-borderless {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 45px;
    &:hover .svg-icon,
    &:focus .svg-icon ,
    &:active .svg-icon {
        transform: rotate(360deg);
    }
}

.btn-link {
    color: $red;
    transition: all .8s;
    border-radius: 0;
    padding: .5rem 0;
    text-decoration: none;
    &:after {
        content: '';
        background: $red;
        width: 0%;
        height: 1px;
        display: flex;
        opacity: 0;
        transition: 0.5s all;
    }
    &:hover,
    &:focus {
        transform: scale(1.1);
        color: $red;
        text-decoration: none;
        &:after {
            content: '';
            background: $red;
            width: 100%;
            height: 1px;
            opacity: 1;
        }
    }
}

// Button with rotating icon
.btn-icon {
    color: $red;
    border-bottom: 1px solid $red;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    svg {
        margin-bottom: 1rem;
        transition: all .8s;
        fill: black;
    }

    &:hover,
    &:focus {
        transform: scale(1.1);
        color: $red;
        border-bottom: 1px solid transparent;

        svg {
            transform: rotate(360deg) scale(1.5);
        }
    }
}

.btn--close {
    z-index: 10;
    position: absolute;
    top: -2px;
    right: -15px;
    background: none;
    @include media-breakpoint-down(md) {
        top: initial;
        bottom: 2px;
    }
}

.btn--search {
    z-index: 5;
    background: none;
}

.nav-link-btn {
    display: flex;
    border-radius: 0;
    color: $base;
    padding: 1.5rem;
    &:not(:last-child) {
        border-bottom: 1px solid rgba(51, 51, 51, 0.2);
    }
    &:hover {
        background: $primary;
        color: $white;
    }
}

.btn-flat {
    background: $white;
    border: 1px solid rgba(#333, 0.1);
    margin: 0.75rem;
    font-size: 12px;
    height: 45px;
    width: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &:hover,
    &:focus,
    &:active,
    &--active {
        background: $primary;
        color: $white;
        border: 1px solid $primary;
    }
}

.btn-freezed {
    cursor: unset !important;
    &:hover,
    &:focus,
    &:active {
        background: none;
        color: $primary;
        :not(svg) {
            fill: $primary;
        }
    }  
}
