// Socials icons
.social-icon {
    width: 1.6rem;
    height: 1.6rem;
    display: inline-block;
    position: relative;

    &--facebook {
        background: url(../img/facebook.svg) no-repeat;
    }

    &--instagram {
        background: url(../img/instagram.svg) no-repeat;
    }
}
