@font-face {
    font-family: 'IntroHeadR-Base';
    src: url('../fonts/IntroHeadR/IntroHeadR-Base.eot');
    src: url('../fonts/IntroHeadR/IntroHeadR-Base.eot?#iefix') format('embedded-opentype'),
        url('../fonts/IntroHeadR/IntroHeadR-Base.woff2') format('woff2'),
        url('../fonts/IntroHeadR/IntroHeadR-Base.woff') format('woff'),
        url('../fonts/IntroHeadR/IntroHeadR-Base.ttf') format('truetype'),
        url('../fonts/IntroHeadR/IntroHeadR-Base.svg#IntroHeadR-Base') format('svg');
    font-weight: normal;
    font-style: normal;
}



// Theme colors
$red:   #C23B3B;
$green: #bef582;
$dark:  #333;
$base: #4D4949;

$primary: $base;
$secondary: $red;
$danger: $red;

// Font
$font-family-base: 'IntroHeadR-Base';
$font-family-secondary: 'Ubuntu', sans-serif;
$font-size-base: 1.5rem;

// Shadows
$box-shadow-1: 0 1.5rem 4rem rgba($base, .15);

// Headings
$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2.4;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 2;
$headings-font-weight: 500;

// Buttons
$btn-padding-y: 1rem;
$btn-padding-x: 2rem;
$btn-border-radius: .5rem;
$btn-transition: all 1250ms cubic-bezier(.19, 1, .22, 1);

// Navbar
$navbar-padding-y: 2rem;
$navbar-padding-x: 0;
$navbar-brand-font-size: 2rem;

// Jumbotron
$jumbotron-padding: 5rem;

// Cards
$card-border-radius: .5rem;
$card-border-width: 0;
$card-border-color: rgba($base, .2);
$card-border-width: 1px;
$card-spacer-x: 2rem;
$card-spacer-y: 3rem;
$card-columns-margin: 1rem;

// Forms
$input-border-width: 1px;
$input-border-color: rgba(128, 128, 128, .28);
$input-color: $base;
$input-padding-y: 2.5rem;
$input-padding-x: 2rem;
$input-border-radius: .5rem;
$form-group-margin-bottom: 2.5rem;
$input-transition: .5 all ease;
