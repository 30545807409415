.c-media {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 3rem;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}
.c-media__content {
  text-align: left;
  margin-left: 3rem;
  height: 200px;
  overflow: hidden;
  width: 100%;
  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-top: 2rem;
  }
}
.c-media__description {
  height: 100px;
  overflow: hidden;
}
.c-media__title {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.c-media__img {
  padding: 2rem;
  border: 1px solid rgba($base, 0.2);
  border-radius: 5px;
  transition: 0.5s ease all;
  img {
    object-fit: contain;
  }
  &:hover {
    transform: scale(1.05);
  }
}
.c-media__btn {
  &:hover {
    transform: none;
  }
}
